import React, { useState, useEffect } from "react";
import { Form, Message } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../../utils";

const SignupForm = ({ referrer }) => {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    schoolName: "",
    email: "",
    loading: false,
    submitted: false,
    errors: [],
    key: Math.random(),
  });

  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  useEffect(() => {
    if (referrer) {
      window.gtag("event", "signup_form", {
        event_label: `${"parent-referral"} ${"page"} ${"parents"}`,
      });
    }
  }, [referrer]);

  const handleChange = (e, v) => {
    const { name, value } = v;
    setState((prevState) => ({ ...prevState, errors: [], [name]: value }));
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    setState((prevState) => ({ ...prevState, loading: true }));
    // eslint-disable-next-line no-unused-vars
    const { firstName, lastName, schoolName, email } = state;
    // eslint-disable-next-line no-unused-vars
    const createdAt = new Date();
    if (!validateEmail(email)) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        errors: [new Error("Please Enter a Valid Email Address")],
      }));
      return;
    }
    window.localStorage.setItem("email", email);
    console.log(" SUBMITTED WITH ", firstName, schoolName, email);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "signup",
        firstName,
        lastName,
        schoolName,
        email,
      }),
    })
      .then(() => console.log(" SUCCEED"))
      .catch((error) => console.log(" ERROR "));
    window.gtag("event", "generate_lead", {
      event_label: `${referrer.page}-${referrer.type}-${referrer.source}`,
    });
    window.fbq("track", "Lead");
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      loading: false,
      submitted: true,
    }));
  };

  const { errors, loading, firstName, lastName, schoolName, submitted, email } =
    state;
  const error = errors.length > 0;
  if (submitted) {
    navigate("/thank-you");
  }

  return (
    <Form
      autoComplete="something"
      error={error}
      size="huge"
      loading={loading}
      onSubmit={handleSubmit}
      name="signup"
      method="post"
    >
      <input type="hidden" name="form-name" value="signup" />
      <Form.Input
        onChange={handleChange}
        autoComplete="something"
        value={firstName}
        name="firstName"
        label="First Name"
        placeholder="First Name"
      />
      <Form.Input
        onChange={handleChange}
        autoComplete="something"
        value={lastName}
        name="lastName"
        label="Last Name"
        placeholder="Last Name"
      />
      <Form.Input
        onChange={handleChange}
        autoComplete="something"
        value={schoolName}
        name="schoolName"
        label="School Name"
        placeholder="School Name"
      />
      <Form.Input
        onChange={handleChange}
        autoComplete="something"
        value={email}
        name="email"
        label="Email Address"
        placeholder="Email Address"
      />
      {errors.map((error, i) => (
        <Message key={`error-${i}`} error content={error.message} />
      ))}
      <Form.Button fluid primary size="huge">
        Submit
      </Form.Button>
    </Form>
  );
};

export default SignupForm;
