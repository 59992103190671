import React from "react";
import { Container, Header, Grid, Segment } from "semantic-ui-react";
import SignupForm from "./SignupForm/SignupForm";
import { useMediaQuery } from "react-responsive";

const referrer = {
  page: "signup",
  type: "page",
  source: "free-signup",
};

const SignupFormContainer = ({ title, quote }) => (
  <Segment style={{ padding: "4em 0em" }} vertical>
    <Container text>
      <Segment style={{ padding: 0 }}>
        <Segment basic padded="very">
          <SignupForm quote={quote} referrer={referrer} />
        </Segment>
      </Segment>
    </Container>
  </Segment>
);

const Signup = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });

  return (
    <Container fluid className="content-buffer">
      <Segment color="teal" basic inverted>
        <Container fluid>
          <Header
            as="h1"
            textAlign="center"
            inverted
            style={{ fontWeight: 300, padding: "2.2em 0 0" }}
          >
            <Header.Content>Sign Up for Free</Header.Content>
            <Header.Subheader>
              Free signup, free concierge onboarding
            </Header.Subheader>
          </Header>
          {isDesktopOrLaptop && (
            <Grid columns="2" centered>
              <Grid.Column>
                <SignupFormContainer
                  title="Sign Up for Free"
                  referrer={referrer}
                />
              </Grid.Column>
            </Grid>
          )}
          {isTabletOrMobile && (
            <SignupFormContainer title="Sign Up for Free" referrer={referrer} />
          )}
        </Container>
      </Segment>
    </Container>
  );
};

export default Signup;
