import React from "react";
import { Container, Header, Grid, Segment } from "semantic-ui-react";
import RequestDemo from "../RequestDemo/RequestDemo";
import "./style.css";

const Demo = () => (
  <Container fluid className="content-buffer">
    <Segment color="teal" basic inverted>
      <Container fluid>
        <Header
          size="huge"
          textAlign="center"
          inverted
          style={{ fontWeight: 300, padding: "2.2em 0 0" }}
        >
          <Header.Content>Free Demo</Header.Content>
          <Header.Subheader>
            Free demo, free signup, free concierge onboarding
          </Header.Subheader>
        </Header>
        <div className="request-demo">
          <Grid columns="2" centered>
            <Grid.Column>
              <RequestDemo
                title="Sign Up for a Free Demo"
                referrer={{
                  page: "demo",
                  type: "page",
                  source: "free-demo",
                }}
              />
            </Grid.Column>
          </Grid>
        </div>
        <div className="request-demo-mobile">
          <RequestDemo
            title="Sign Up for a Free Demo"
            referrer={{
              page: "demo",
              type: "page",
              source: "free-demo",
            }}
          />
        </div>
      </Container>
    </Segment>
  </Container>
);

export default Demo;
