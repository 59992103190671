import React from "react";
import { Segment, Grid, Header } from "semantic-ui-react";

const Testimonial = () => (
  <Segment style={{ padding: "0em" }} vertical>
    <Grid celled="internally" columns="equal" stackable>
      <Grid.Row textAlign="center">
        <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
          <Header
            color="pink"
            as="h3"
            style={{ fontSize: "2em", fontWeight: 300 }}
          >
            "Since we began using CommunityRoot, my job has been so much easier
            and less stressful."
          </Header>
          <p style={{ fontSize: "1.33em" }}>
            <b>Becky A</b> Learn to Grow
          </p>
        </Grid.Column>
        <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
          <Header
            color="purple"
            as="h3"
            style={{ fontSize: "2em", fontWeight: 300 }}
          >
            "Parents are so excited about the convenience of online
            registration. It's really helped us differentiate and grow our
            enrollments."
          </Header>
          <p style={{ fontSize: "1.33em" }}>
            <b>Melanie B</b> Creative Horizons
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

export default Testimonial;
