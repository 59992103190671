import React from "react";
import { Grid, Header, Icon, Segment } from "semantic-ui-react";

const Features = () => (
  <Segment
    textAlign="center"
    style={{ padding: "3em 0" }}
    basic
    className="body"
    vertical
  >
    <Header color="green" as="h1" textAlign="center" className="middle-header">
      What you can do with CommunityRoot
    </Header>
    <Grid stackable celled="internally">
      <Grid.Row columns={3}>
        <Grid.Column className="benefits-column">
          <Segment basic>
            <Header as="h2" icon>
              <Icon name="settings" circular color="orange" inverted />
              <Header.Content>Automate Registrations</Header.Content>
              <Header.Subheader>
                Flexible, automated registrations
              </Header.Subheader>
              <Header.Subheader>Online schedule and payments</Header.Subheader>
              <Header.Subheader>Fully customizable control </Header.Subheader>
            </Header>
          </Segment>
        </Grid.Column>
        <Grid.Column className="benefits-column">
          <Segment basic>
            <Header as="h2" icon>
              <Icon name="clock outline" circular color="teal" inverted />
              <Header.Content>Save Time</Header.Content>
              <Header.Subheader>
                Saves up to 1 hour per day per staff member
              </Header.Subheader>
              <Header.Subheader>
                Automated Registration and Financial Reports
              </Header.Subheader>
            </Header>
          </Segment>
        </Grid.Column>
        <Grid.Column className="benefits-column">
          <Segment basic>
            <Header as="h2" icon style={{ lineHeight: "1.4em" }}>
              <Icon name="heart" circular color="pink" inverted />
              Delight Parents and Staff
              <Header.Subheader>
                Parents love the convenience of CommunityRoot
              </Header.Subheader>
              <Header.Subheader>
                Staff spends less time on administrative overhead
              </Header.Subheader>
            </Header>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

export default Features;
