import React from "react";

import "./Home.css";
import { Container } from "semantic-ui-react";
import Hero from "./Hero/Hero";
import Testimonial from "./Testimonial/Testimonial";
import Partners from "./Partners/Partners";
import Features from "./Features/Features";
import Steps from "./Steps/Steps";
import NeedAndLove from "./NeedAndLove/NeedAndLove";

// const content = <DesktopMenu src={src} fixed={fixed} />;
//     const active = true;
/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */

const HomepageLayout = props => (
  <Container fluid>
    <Hero />
    <Testimonial />
    <Partners />
    <Steps />
    <Features />
    <NeedAndLove />
  </Container>
);

export default HomepageLayout;
