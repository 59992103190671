import React from "react";
import { Header, Image, Segment } from "semantic-ui-react";
import partners from "./partnerImages";

const Partners = () => (
  <Segment
    style={{ padding: "5em 0", marginTop: 0 }}
    // color="grey"
    // inverted
    vertical
    className="body"
  >
    <Header textAlign="center" color="green" as="h1" className="middle-header">
      <Header.Content>300,000+ Student Registrations</Header.Content>
      <Header.Subheader style={{ fontSize: "0.6em" }}>
        A few of our partners
      </Header.Subheader>
    </Header>
    <section className="card">
      {partners.shuffle(partners.items).map((partner, i) => (
        <div key={`partner-${i}`} className="card--content">
          <Image centered verticalAlign="middle" size="small" src={partner} />
        </div>
      ))}
    </section>
  </Segment>
);

export default Partners;
