import React from "react";
import { Button, Segment, Container, Header, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";

const NeedAndLove = () => (
  <Segment style={{ padding: "8em 0em" }} vertical>
    <Container text>
      <Header as="h3" style={{ fontSize: "2em" }}>
        The childcare registration management platform you need
      </Header>
      <p style={{ fontSize: "1.33em" }}>
        Whether you run a daycare, preschool or after school program, we have
        the right solution for you. We understand the complex registration needs
        of after school programs, recurring billing and parent communication
        needs of daycare.
      </p>
      <Button
        onClick={() => {
          window.gtag("event", "click", {
            event_label: "Learn More Button Demo"
          });
        }}
        as={Link}
        to="/demo"
        size="large"
      >
        Learn More
      </Button>
      <Divider
        as="h4"
        className="header"
        horizontal
        style={{ margin: "3em 0em", textTransform: "uppercase" }}
      >
        <a href="/demo">Free Demo</a>
      </Divider>
      <Header as="h3" style={{ fontSize: "2em" }}>
        The solution your parents will love
      </Header>
      <p style={{ fontSize: "1.33em" }}>
        100% of parents surveyed have indicated they're better off since their
        programs have switched to CommunityRoot. Online registration convience.
        Easy to use on your mobile device and computer. What's not to love?
      </p>
      <Button
        onClick={() => {
          window.gtag("event", "click", {
            event_label: "Need and Love Button Signup"
          });
        }}
        primary
        as={Link}
        to="/signup"
        size="large"
      >
        Sign Up for Free
      </Button>
    </Container>
  </Segment>
);

export default NeedAndLove;
