import React from "react";
import {
  Container,
  Segment,
  Grid,
  Header,
  Label,
  Table,
} from "semantic-ui-react";

const ThankYou = () => (
  <Container fluid className="content-buffer">
    <Segment color="teal" basic inverted padded="very">
      <Container>
        <Header
          size="huge"
          textAlign="center"
          inverted
          style={{ fontWeight: 300, padding: "1.2em" }}
        >
          <Header.Content>Thank you for contacting us!</Header.Content>
          <Header.Subheader>We will be in touch shortly</Header.Subheader>
        </Header>
        <Grid columns="2" centered>
          <Grid.Column>
            <Segment padded="very">
              <Header as="h1" textAlign="center">
                The Next Steps
              </Header>
              <Table basic="very">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell textAlign="center" width="1">
                      <Label
                        color="teal"
                        size="massive"
                        content="1"
                        circular
                        style={{ float: "left", marginRight: "1em" }}
                      />
                    </Table.Cell>
                    <Table.Cell width="15">
                      <Header as="h3">
                        <Header.Content>
                          Let's get to know eachother
                          <Header.Subheader>
                            We will reach out to schedule a short 15-20 minute
                            phone conversation. We hope to get a glimpse into
                            your goals, needs and pain points so we can compile
                            some recommendations for cross-platform use cases.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="center" width="1">
                      <Label
                        color="teal"
                        size="massive"
                        content="2"
                        circular
                        style={{ float: "left", marginRight: "1em" }}
                      />
                    </Table.Cell>
                    <Table.Cell width="15">
                      <Header as="h3">
                        <Header.Content>
                          Take a look at CommunityRoot in action
                          <Header.Subheader>
                            Bring your team! In this video conference, we'll
                            show you how you can use CommunityRoot's features to
                            setup and collect online registrations and payments,
                            give you a detailed look into the product, and
                            answer any questions you have.
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
    {/* <Segment style={{ padding: "4em 0em" }} vertical>
      <Container text>
        <Segment style={{ padding: 0 }}>
          <Segment basic padded="very" color="grey" inverted>
            <Header color="pink" as="h1" style={{ fontWeight: 300 }}>
              Sign Up for a Free Demo
            </Header>
          </Segment>
          <Segment basic padded="very" />
        </Segment>
      </Container>
    </Segment> */}
  </Container>
);

export default ThankYou;
