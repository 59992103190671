import React from "react";
import { Container, Segment, Grid, Header } from "semantic-ui-react";
import PriorityForm from "./PriorityForm/PriorityForm";

const ThankYou = () => (
  <Container fluid className="content-buffer">
    <Segment color="teal" basic inverted padded="very">
      <Container>
        <Header
          size="huge"
          textAlign="center"
          inverted
          style={{ fontWeight: 300, padding: "1.2em" }}
        >
          <Header.Content>
            Thank you for Signing up for CommunityRoot
          </Header.Content>
          <Header.Subheader>
            Due to increased levels of signups, you've been added to the
            CommunityRoot waitlist.
          </Header.Subheader>
        </Header>
        <Grid columns="2" centered>
          <Grid.Column>
            <Segment padded="very">
              <PriorityForm />
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
    {/* <Segment style={{ padding: "4em 0em" }} vertical>
      <Container text>
        <Segment style={{ padding: 0 }}>
          <Segment basic padded="very" color="grey" inverted>
            <Header color="pink" as="h1" style={{ fontWeight: 300 }}>
              Sign Up for a Free Demo
            </Header>
          </Segment>
          <Segment basic padded="very" />
        </Segment>
      </Container>
    </Segment> */}
  </Container>
);

export default ThankYou;
