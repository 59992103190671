import React from "react";
import { Container, Segment, Grid, Header, Icon } from "semantic-ui-react";
import RequestDemo from "../RequestDemo/RequestDemo";

const AfterSchool = () => (
  <Container fluid className="content-buffer">
    <Segment color="purple" basic inverted padded="very">
      <Container>
        <Header
          size="huge"
          textAlign="center"
          as="h1"
          inverted
          style={{ fontWeight: 300, padding: "1.2em" }}
        >
          <Header.Content>CommunityRoot for After School</Header.Content>
          <Header.Subheader>
            Flexible childcare management platform to cover all of your needs
            regardless of size
          </Header.Subheader>
        </Header>
        <Grid columns="3" stackable>
          <Grid.Column>
            <Segment padded="very">
              <Header as="h2" icon textAlign="center">
                <Icon name="wrench" inverted circular color="orange" />
                Flexible Registration
                <Header.Subheader>
                  #1 in registration and scheduling flexibilty
                </Header.Subheader>
              </Header>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment padded="very">
              <Header as="h2" icon textAlign="center">
                <Icon name="money" inverted circular color="green" />
                Online Payments
                <Header.Subheader>
                  Registration, discounts, fees and price automation.
                </Header.Subheader>
              </Header>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment padded="very">
              <Header as="h2" icon textAlign="center">
                <Icon name="chart line" inverted circular color="blue" />
                Reports
                <Header.Subheader>
                  Manage your account settings and set e-mail preferences.
                </Header.Subheader>
              </Header>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
    <RequestDemo
      title="Sign Up for a Free Demo"
      referrer={{
        page: "afterschool",
        type: "page",
        source: "free-demo"
      }}
    />
  </Container>
);

export default AfterSchool;
