import React, { Component } from "react";
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import { BrowserRouter } from "react-router-dom";

import AppRouter from "./Router";

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
