import React from "react";
import { Segment } from "semantic-ui-react";
import HomepageHeading from "./HomepageHeading/HomepageHeading";
import "./Hero.css";

const Hero = () => {
  return (
    <Segment textAlign="center" className="hero-banner" vertical>
      {/*       
      <Dimmer.Dimmable
        as={Image}
        dimmed={true}
        dimmer={{ true: true, content }}
        style={{
          position: "absolute",
          height: 540,
          // marginTop: "76px",
          padding: "0em 0em"
        }}
        fluid
        src="https://storage.googleapis.com/communityrootbucket/hero-kids.jpg"
        className="HeroDimmer"
      /> */}
      <HomepageHeading />
    </Segment>
  );
};

export default Hero;
