import React from "react";
import PropTypes from "prop-types";
import { Button, Container, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as="h1"
      content="The childcare platform you've been looking for"
      inverted
      style={{
        fontSize: mobile ? "2.0em" : "3.5em",
        fontWeight: "normal",
        marginBottom: 0,
        paddingTop: mobile ? "1.5em" : "2.20em"
      }}
    />
    <Header
      as="h2"
      content="The easy to use, all in one solution to help you save time and money while delighting parents with the convenience of online registration"
      inverted
      style={{
        fontSize: mobile ? "1.0em" : "1.2em",
        fontWeight: "normal",
        marginTop: mobile ? "0.5em" : "1.5em"
      }}
    />
    <Button
      onClick={() => {
        window.gtag("event", "click", { event_label: "Hero Button Sign Up" });
      }}
      as={Link}
      to="/signup"
      primary
      size="huge"
    >
      Sign Up for Free
    </Button>
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool
};

export default HomepageHeading;
