import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import ResponsiveContainer from "./components/ResponsiveContainer/ResponsiveContainer";
import Pricing from "./components/Pricing/Pricing";
import Daycare from "./components/Daycare/Daycare";
import Preschool from "./components/Preschool/Preschool";
import AfterSchool from "./components/AfterSchool/AfterSchool";
import Demo from "./components/Demo/Demo";
import Signup from "./components/Signup/Signup";
import Waitlist from "./components/Waitlist/Waitlist";
import ThankYou from "./components/ThankYou/ThankYou";
import ThankYouReferral from "./components/ThankYouReferral/ThankYouReferral";
import ThankYouPriority from "./components/ThankYouPriority/ThankYouPriority";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    if (location) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return null;
};

const Router = () => (
  <ResponsiveContainer>
    <ScrollToTop />
    <Routes>
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/daycare" element={<Daycare />} />
      <Route path="/preschool" element={<Preschool />} />
      <Route path="/afterschool" element={<AfterSchool />} />
      <Route path="/demo" element={<Demo />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/waitlist" element={<Waitlist />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/thank-you-priority" element={<ThankYouPriority />} />
      <Route path="/thank-you-referral" element={<ThankYouReferral />} />
      <Route path="/*" element={<Home />} />
    </Routes>
    <Footer />
  </ResponsiveContainer>
);

export default Router;
