import React from "react";
import { Link } from "react-router-dom";

import { Button, Grid, Header, Image, Segment } from "semantic-ui-react";
const Steps = () => (
  <Segment style={{ padding: "8em 0em" }} vertical inverted color="grey">
    <Grid container stackable verticalAlign="middle" size="small">
      <Grid.Row>
        <Grid.Column width={8}>
          <Header color="yellow" as="h3" style={{ fontSize: "2em" }}>
            We Partner with You
          </Header>
          <Segment inverted={false} basic style={{ padding: 0 }}>
            <p style={{ fontSize: "1.33em" }}>
              We're partners and invested in your success. From the moment you
              sign up for you're account, we're there to support you the entire
              way, starting with our free concerierge onboarding
            </p>
          </Segment>
        </Grid.Column>
        <Grid.Column floated="right" width={6}>
          <Image
            bordered
            rounded
            size="massive"
            src="https://storage.googleapis.com/communityrootbucket/reports-demo%40403.png"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Button
            as={Link}
            to="/demo"
            onClick={() => {
              window.gtag("event", "click", {
                event_label: "Free Onboarding Button Demo"
              });
            }}
            primary
            size="huge"
          >
            Request Demo
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

export default Steps;
