import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MobileMenu from "./MobileMenu/MobileMenu";

// You can decide your own break point for mobile
const MOBILE_BREAKPOINT = 767;

const ResponsiveContainer = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // If the window width is less than or equal to mobile breakpoint, render children
  if (windowWidth <= MOBILE_BREAKPOINT) {
    return children;
  }

  // Otherwise, don't render anything
  return null;
};

const MobileContainer = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const [fixed, setFixed] = useState(true);

  const src = fixed
    ? "https://storage.googleapis.com/communityrootbucket/logo.jpg"
    : "https://storage.googleapis.com/communityrootbucket/logoWhite.png";

  return (
    <ResponsiveContainer>
      <MobileMenu src={src} fixed>
        {children}
      </MobileMenu>
    </ResponsiveContainer>
  );
};

MobileContainer.propTypes = {
  children: PropTypes.node,
};

export default MobileContainer;
