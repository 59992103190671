import React from "react";
import { Link } from "react-router-dom";

import {
  Header,
  List,
  Segment,
  Container,
  Grid,
  Icon,
} from "semantic-ui-react";
const Footer = () => (
  <Segment color="grey" inverted vertical style={{ padding: "5em 0em" }}>
    <Segment inverted={false} basic style={{ padding: 0 }}>
      <Container>
        <Grid divided inverted={false} stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header as="h4" content="Solutions" />
              <List link>
                <List.Item as={Link} to="/daycare">
                  Daycare & Preschool
                </List.Item>
                <List.Item as={Link} to="/afterschool">
                  After School
                </List.Item>
                <List.Item as={Link} to="/parents">
                  Parents
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header as="h4" content="Resources" />
              <List link>
                <List.Item as={Link} to="/pricing">
                  Pricing
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as="h4">Social Media</Header>
              <List link>
                <List.Item
                  as="a"
                  href="https://www.instagram.com/communityroot/"
                  target="_blank"
                >
                  <Icon name="instagram" /> Instagram
                </List.Item>
                <List.Item
                  as="a"
                  target="_blank"
                  href="https://www.facebook.com/communityroot/"
                >
                  <Icon name="facebook" /> Facebook
                </List.Item>
                <List.Item
                  target="_blank"
                  as="a"
                  href="https://www.twitter.com/communityroot/"
                >
                  <Icon name="twitter" /> Twitter
                </List.Item>
                <List.Item
                  target="_blank"
                  as="a"
                  href="https://www.medium.com/@communityroot/"
                >
                  <Icon name="medium" /> Blog
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </Segment>
);

export default Footer;
