import React from "react";
import {
  // eslint-disable-next-line no-unused-vars
  Button,
  Container,
  Header,
  Image,
  Icon,
  Menu,
  Segment,
  Sidebar,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

class MobileMenu extends React.Component {
  state = {};
  handlePusherClick = () => {
    const { sidebarOpened } = this.state;

    if (sidebarOpened) this.setState({ sidebarOpened: false });
  };
  handleItemClick = () => this.setState({ sidebarOpened: false });
  handleToggle = () =>
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  render() {
    const { children, src } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="push"
          inverted
          vertical
          visible={sidebarOpened}
          color="teal"
        >
          <Menu.Item onClick={this.handleItemClick} as={Link} to="/">
            <Header inverted as="h3" style={{ marginTop: 0, fontWeight: 300 }}>
              {/* <Image
            size="small"
            src={src}
            // style={{ width: "30px", paddingRight: "0.5em" }}
          />
          CommunityRoot */}
              <Image
                circular
                // src="https://react.semantic-ui.com/images/avatar/large/patrick.png"
                style={{ marginTop: "-4px", width: "1.2em" }}
                src={src}
              />
              CommunityRoot
            </Header>
          </Menu.Item>
          <Menu.Item onClick={this.handleItemClick} as={Link} to="/">
            Home
          </Menu.Item>
          <Menu.Item onClick={this.handleItemClick} as={Link} to="/daycare">
            Daycare & Preschool
          </Menu.Item>
          <Menu.Item onClick={this.handleItemClick} as={Link} to="/afterschool">
            After School
          </Menu.Item>
          <Menu.Item onClick={this.handleItemClick} as={Link} to="/pricing">
            Pricing
          </Menu.Item>
          <Menu.Item onClick={this.handleItemClick} as={Link} to="/demo">
            Request Demo
          </Menu.Item>
        </Sidebar>
        <Sidebar.Pusher
          dimmed={sidebarOpened}
          onClick={this.handlePusherClick}
          style={{ minHeight: "100vh" }}
        >
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 73, padding: "1em 0em" }}
            vertical
            color="teal"
          >
            <Container>
              <Menu borderless inverted color="teal" size="large">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

export default MobileMenu;
