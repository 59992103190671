import React from 'react';
import { Container, Segment, Grid, Header, Icon } from 'semantic-ui-react';
import RequestDemo from '../RequestDemo/RequestDemo';

const Preschool = () => (
  <Container fluid className="content-buffer">
    <Segment color="teal" basic inverted padded="very">
      <Container>
        <Header
          size="huge"
          as="h1"
          textAlign="center"
          inverted
          style={{ fontWeight: 300, padding: '1.2em' }}
        >
          <Header.Content>CommunityRoot for Preschool</Header.Content>
          <Header.Subheader>
            Flexible preschool management platform to cover all of your needs
            regardless of size
          </Header.Subheader>
        </Header>
        <Grid columns="3" stackable>
          <Grid.Column>
            <Segment padded="very">
              <Header as="h2" icon textAlign="center">
                <Icon name="users" color="blue" />
                Staff Management
                <Header.Subheader>
                  Add your staff, control access, sign in and sign out
                </Header.Subheader>
              </Header>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment padded="very">
              <Header as="h2" icon textAlign="center">
                <Icon name="money" color="green" />
                Automated Billing
                <Header.Subheader>
                  Registration, discounts, fees and price automation.
                </Header.Subheader>
              </Header>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment padded="very">
              <Header as="h2" icon textAlign="center">
                <Icon name="comments" color="pink" />
                Parent Engagement
                <Header.Subheader>
                  Manage your account settings and set e-mail preferences.
                </Header.Subheader>
              </Header>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
    <RequestDemo
      title="Sign Up for a Free Demo"
      referrer={{
        page: 'Preschool',
        type: 'page',
        source: 'free-demo',
      }}
    />
  </Container>
);

export default Preschool;
