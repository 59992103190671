import React from "react";
import {
  Button,
  Container,
  Modal,
  Segment,
  Grid,
  Header,
  List,
  Divider
} from "semantic-ui-react";
import RequestDemo from "../RequestDemo/RequestDemo";

const Pricing = ({ mobile }) => (
  <Container fluid>
    <Segment textAlign="left" className="parents-banner" vertical inverted>
      <Container>
        <Grid columns={2}>
          <Grid.Column>
            <Header
              as="h1"
              content="The software solution you need"
              inverted
              style={{
                fontSize: mobile ? "2.0em" : "3.5em",
                fontWeight: "normal",
                marginBottom: 0,
                paddingTop: mobile ? "3.0em" : "4.20em"
              }}
            />
            <Header
              as="h2"
              content="Automate reports, registrations and payments. Delight families."
              inverted
              style={{
                fontSize: mobile ? "1.0em" : "1.8em",
                fontWeight: 300
              }}
            />
            <Modal
              trigger={
                <Button primary size="huge">
                  Request a Quote
                </Button>
              }
            >
              <Modal.Content>
                <RequestDemo
                  title="Request a Quote"
                  quote={true}
                  referrer={{
                    page: "pricing",
                    type: "modal",
                    source: "request-quote"
                  }}
                />
              </Modal.Content>
            </Modal>
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
    <Container className="content-buffer">
      <Segment style={{ padding: "4em 0" }} vertical>
        <Grid stackable centered columns="3">
          <Grid.Column>
            <Segment style={{ padding: 0, overflow: "hidden" }}>
              <Segment
                basic
                style={{ backgroundColor: "#adadad" }}
                inverted
                padded
              >
                <Header>Basic</Header>
              </Segment>
              <Segment basic padded>
                <Header style={{ color: "#adadad" }}>Includes:</Header>
                <Divider />
                <List size="large">
                  <List.Item>Unlimited Students, Family Data</List.Item>
                  <List.Item>1 Staff Member</List.Item>
                  <List.Item>Daily Attendance</List.Item>
                  <List.Item>Photos & Videos</List.Item>
                  <List.Item>Daily Reports - Meals, Naps & More</List.Item>
                  <List.Item>Parent Messaging</List.Item>
                  <List.Item>Calendar Events</List.Item>
                  <List.Item>Learning & Tracking</List.Item>
                  <List.Item>Customizable Registration Forms</List.Item>
                </List>
              </Segment>
              <Segment
                basic
                style={{ backgroundColor: "#adadad" }}
                inverted
                padded
              >
                <Header>Free</Header>
              </Segment>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment style={{ padding: 0, overflow: "hidden" }}>
              <Segment basic color="teal" inverted padded>
                <Header>Premium</Header>
              </Segment>
              <Segment basic padded>
                <Header color="teal">Everything in basic, plus:</Header>
                <Divider />
                <List size="large">
                  <List.Item>Check in & Check Out</List.Item>
                  <List.Item>Online Payment Processing</List.Item>
                  <List.Item>Recurring Billing & Invoicing</List.Item>
                  <List.Item>All Reporting</List.Item>
                  <List.Item>Calendar Events</List.Item>
                  <List.Item>Learning & Tracking</List.Item>
                  <List.Item>Unlimited Staff</List.Item>
                  <List.Item>Full Support</List.Item>
                  <List.Item>Email & SMS Messaging</List.Item>
                </List>
              </Segment>
              <Segment basic color="teal" inverted padded>
                <Header>Monthly fee based on enrollment</Header>
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment style={{ padding: "4em 0" }} vertical>
        <RequestDemo
          title="Sign Up for a Free Demo"
          referrer={{
            page: "pricing",
            type: "link",
            source: "free-demo"
          }}
        />
      </Segment>
    </Container>
  </Container>
);

export default Pricing;
