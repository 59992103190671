import React from "react";
import {
  Button,
  Container,
  Dropdown,
  Image,
  Menu,
  Header,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

const DesktopMenu = ({ fixed, src }) => (
  <Menu
    fixed={fixed ? "top" : null}
    inverted={!fixed}
    // pointing={!fixed}
    secondary={!fixed}
    borderless
    size="massive"
  >
    <Container fluid>
      <Menu.Item as={Link} to="/">
        <Header color="black" as="h2" style={{ fontWeight: 300 }}>
          {/* <Image
            size="small"
            src={src}
            // style={{ width: "30px", paddingRight: "0.5em" }}
          />
          CommunityRoot */}
          <Image
            circular
            // src="https://react.semantic-ui.com/images/avatar/large/patrick.png"
            style={{ marginTop: 0, width: "2.0em" }}
            src={src}
          />
          CommunityRoot
        </Header>
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item as={Link} to="/">
          Home
        </Menu.Item>
        <Dropdown item text="Solutions">
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/daycare">
              Daycare
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/preschool">
              Preschool
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/afterschool">
              After School
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item as={Link} to="/pricing">
          Pricing
        </Menu.Item>
        <Menu.Item>
          <Button
            as={Link}
            to="/demo"
            inverted={!fixed}
            primary={fixed}
            style={{ marginLeft: "0.1em" }}
            basic
          >
            Free Demo
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button
            as={Link}
            to="/signup"
            inverted={!fixed}
            primary={fixed}
            style={{ marginLeft: "0.1em" }}
          >
            Sign Up
          </Button>
        </Menu.Item>
      </Menu.Menu>
    </Container>
  </Menu>
);

export default DesktopMenu;
