import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DesktopMenu from "./DesktopMenu/DesktopMenu";

// You can decide your own break point for tablet
const TABLET_BREAKPOINT = 768;

const ResponsiveContainer = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // If the window width is greater than tablet breakpoint, render children
  if (windowWidth >= TABLET_BREAKPOINT) {
    return children;
  }

  // Otherwise, don't render anything
  return null;
};

const DesktopContainer = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const [fixed, setFixed] = useState(true);

  const src = fixed
    ? "https://storage.googleapis.com/communityrootbucket/logo.jpg"
    : "https://storage.googleapis.com/communityrootbucket/logoWhite.png";

  return (
    <ResponsiveContainer>
      <DesktopMenu src={src} fixed />
      {children}
    </ResponsiveContainer>
  );
};

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

export default DesktopContainer;
