import React from "react";
import { Segment, Container, Header } from "semantic-ui-react";
import RequestDemoForm from "./RequestDemoForm/RequestDemoForm";
const RequestDemo = ({ title, referrer, quote }) => (
  <Segment style={{ padding: "4em 0em" }} vertical>
    <Container text>
      <Segment style={{ padding: 0 }}>
        <Segment basic padded="very" color="grey" inverted>
          <Header color="white" as="h1" style={{ fontWeight: 300 }}>
            {title}
          </Header>
        </Segment>
        <Segment basic padded="very">
          <RequestDemoForm quote={quote} referrer={referrer} />
        </Segment>
      </Segment>
    </Container>
  </Segment>
);

export default RequestDemo;
