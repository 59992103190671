const items = [
  "https://storage.googleapis.com/crwebsiteavatars/websiteavatars/base.png",
  "https://storage.googleapis.com/crwebsiteavatars/websiteavatars/grow.png",
  "https://storage.googleapis.com/crwebsiteavatars/websiteavatars/ms.png",
  "https://storage.googleapis.com/crwebsiteavatars/websiteavatars/pm.jpg",
  "https://storage.googleapis.com/crwebsiteavatars/websiteavatars/sol.png",
  "https://storage.googleapis.com/crwebsiteavatars/websiteavatars/trin.png",
  "https://storage.googleapis.com/crwebsiteavatars/websiteavatars/union.png",
  "https://storage.googleapis.com/crwebsiteavatars/websiteavatars/unknown.jpg",
  "https://storage.googleapis.com/crwebsiteavatars/websiteavatars/west.png",
  "https://storage.googleapis.com/crwebsiteavatars/websiteavatars/wow.png",
];

const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

const Public = { shuffle, items };
export default Public;
