import React from "react";
import { Container, Segment, Header } from "semantic-ui-react";

const ThankYouReferral = () => (
  <Container fluid className="content-buffer">
    <Segment
      color="teal"
      basic
      inverted
      padded="very"
      style={{ minHeight: "500px" }}
    >
      <Container text>
        <Header
          size="huge"
          textAlign="center"
          inverted
          style={{ fontWeight: 300, padding: "2.2em" }}
        >
          <Header.Content>
            Thank you for referring your school to CommunityRoot
          </Header.Content>
          <Header.Subheader>
            We will be in touch shortly with your school
          </Header.Subheader>
          <Header.Subheader>
            Follow us on social media to stay up to date
          </Header.Subheader>
        </Header>
      </Container>
    </Segment>
  </Container>
);

export default ThankYouReferral;
