import React, { useState, useEffect } from "react";
import { Form, Message, Header } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

const PriorityForm = ({ referrer }) => {
  const [state, setState] = useState({
    message: "",
    loading: false,
    submitted: false,
    errors: [],
    key: Math.random(),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (referrer) {
      window.gtag("event", "priority_form", {
        event_label: `${"parent-referral"} ${"page"} ${"parents"}`,
      });
    }
  }, [referrer]);

  const handleChange = (e, v) => {
    const { name, value } = v;
    setState((prevState) => ({ ...prevState, errors: [], [name]: value }));
  };

  const handleSubmit = () => {
    setState((prevState) => ({ ...prevState, loading: true }));
    // eslint-disable-next-line no-unused-vars
    const { message } = state;

    // eslint-disable-next-line no-unused-vars
    const createdAt = new Date();
    // eslint-disable-next-line no-unused-vars
    const email = window.localStorage.getItem("email");

    // firebase
    //   .firestore()
    //   .collection("priority")
    //   .add({
    //     message,
    //     email,
    //     createdAt,
    //   })
    //   .then((docRef) => {
    //     setState((prevState) => ({
    //       ...prevState,
    //       loading: false,
    //       submitted: true,
    //     }));

    //     window.gtag("event", "generate_lead", {
    //       event_label: `${referrer.page}-${referrer.type}-${referrer.source}`,
    //     });
    //     window.fbq("track", "Lead");
    //   })
    //   .catch((error) => {
    //     setState((prevState) => ({
    //       ...prevState,
    //       loading: false,
    //       submitted: false,
    //       error: true,
    //       errors: [new Error("An unknown error occurred. Please try again.")],
    //     }));

    //     window.gtag("event", "error", {
    //       event_label: error.message,
    //     });
    //   });
  };

  const { errors, submitted, loading, message } = state;
  const error = errors.length > 0;
  if (submitted) {
    navigate("/thank-you-priority");
  }

  return (
    <Form
      autoComplete="something"
      error={error}
      size="massive"
      loading={loading}
      data-netlify="true"
      onSubmit={handleSubmit}
      name="priority"
    >
      <input type="hidden" name="form-name" value="priority" />

      <Header size="small" style={{ fontWeight: "300" }}>
        Please send us the details of your program, including any specific dates
        you'd like access by
      </Header>
      <Form.TextArea
        onChange={handleChange}
        autoComplete="something"
        value={message}
        name="message"
        label=""
      />
      {errors.map((error, i) => (
        <Message key={`error-${i}`} error content={error.message} />
      ))}
      <Form.Button fluid primary size="massive">
        Submit
      </Form.Button>
    </Form>
  );
};

export default PriorityForm;
